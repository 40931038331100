<template>
  <div class="parkingBerthAnalysis">
    <el-row :gutter="20">
      <el-col :span="10">
        <div class="parkingRight commonF">
          <h4 class="parkH4">
            <i class="park_h4_spack"></i>
            <span>日均泊位时长利用率排行</span>
            <el-tooltip
              class="item"
              effect="dark"
              content="停车场日均泊位时长，数据来自于最近30天，各个车场日均泊位时长的平均利用率"
              placement="top"
            >
              <i class="el-icon-question parking-icon park_h4_spack_icon"></i>
            </el-tooltip>
          </h4>
          <p>全部车场日均泊位时长利用率：{{ parkData.berthUtilization }}</p>
          <!-- <el-input placeholder="请输入内容" v-model="tableParams.parkName">
            <el-button slot="append" icon="el-icon-search" @click="getDataTable('name')">{{ $t('button.search') }}</el-button>
          </el-input> -->
          <el-row :gutter="4" class="paddingB10">
            <el-col :span="9" class="parkRanking">
              <my-component
                ref="parkInput"
                class="parking_search"
                dataSource="1"
                slaveRelations="0,1"
                commercialStatus="2,3"
                parkType="1,2,4"
                @valueChange="completeValue"
              >
              </my-component>
              <!--              <el-input v-model="tableParams.parkName" placeholder="请输入停车场名称"></el-input>-->

              <!--              <span class="parkRanking_span" @click="tableParams.page = 1;getDataTable('name')">查询</span>-->
            </el-col>
            <el-col :span="7">
              <el-select v-model="tableParams.tag">
                <el-option label="全部" value=""></el-option>
                <el-option label="低频长停车场" value="1"></el-option>
                <el-option label="冷门车场" value="2"></el-option>
                <el-option label="热门车场" value="3"></el-option>
                <el-option label="高频短停车场" value="4"></el-option>
              </el-select>
            </el-col>
            <el-col :span="2">
              <el-button
                @click="
                  tableParams.page = 1;
                  getDataTable('name');
                "
                icon="el-icon-search"
                type="primary"
                style="margin: 2px 0 0 6px"
                >{{ $t('button.search') }}</el-button
              >
            </el-col>
          </el-row>
          <el-table
            :data="tableData"
            ref="singleTable"
            highlight-current-row
            @row-click="rowTable"
            style="width: 100%"
          >
            <el-table-column align="center" label="排名" type="index" width="50"> </el-table-column>
            <el-table-column prop="parkName" :label="$t('searchModule.park_name')"> </el-table-column>
            <el-table-column prop="berthUtilization" label="日均泊位时长利用率"> </el-table-column>
            <el-table-column prop="tagDesc" label="标签"> </el-table-column>
          </el-table>
          <el-pagination
            style="position: absolute; bottom: 20px"
            @current-change="handleCurrentChange"
            :current-page="tableParams.page"
            :page-size="tableParams.pageSize"
            layout="total, prev, pager, next"
            :total="total"
          >
          </el-pagination>
        </div>
      </el-col>
      <el-col :span="14">
        <p class="park_title_name">停车场名称：{{ tableParkName }}</p>
        <el-row :gutter="30">
          <el-col :span="8">
            <div class="parkingTop parkingTop_yellow">
              <p>车场标签</p>
              <p>{{ tagDesc }}</p>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="parkingTop parkingTop_blue">
              <p>日均泊位周转次数</p>
              <p>{{ berthTurnover }}次</p>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="parkingTop parkingTop_cyan">
              <p>平均停车时长</p>
              <p>{{ avgParkTime }}</p>
            </div>
          </el-col>
        </el-row>
        <div class="parking_echarts commonF">
          <h4 class="parkH4">
            <i class="park_h4_spack"></i>
            <span>日均泊位周转次数 — 平均停车时长分析</span>
            <el-tooltip
              class="item"
              effect="dark"
              content="日均泊位周转次数-平均停车时长分析，数据来自于最近30天，每天对应车场的日均泊位周转次数平均值以及每个订单平均停车时长平均值"
              placement="top"
            >
              <i class="el-icon-question parking-icon park_h4_spack_icon"></i>
            </el-tooltip>
          </h4>
          <div id="parkingNums" style="width: 100%; height: 400px"></div>
        </div>
        <parking-map :park_id="parkId" :activePark="activePark" class="parking_map commonF" />
      </el-col>
    </el-row>
  </div>
</template>
<script>
import parkingMap from "./parkingMap";
import myComponent from "@/components/autocomplete/myautoComponentBerth";
import { formatDuring } from "@/common/js/public.js";
export default {
  components: {
    parkingMap,
    myComponent,
  },
  data() {
    return {
      activePark: {},
      parkData: {},
      tableParams: {
        parkId: "",
        tag: "",
        page: 1,
        pageSize: 15,
        parkName: "",
        slaveRelations: "1,2",
      },
      parkId: "",
      total: 1,
      tableData: [],
      baseLineX: 0,
      baseMaxX: 0,
      baseLineY: 0,
      baseMaxY: 0,
      tagDesc: "",
      berthTurnover: "",
      avgParkTime: "",
      tableParkName: "",
    };
  },
  methods: {
    completeValue(item) {
      if (item) {
        this.tableParams.parkName = item.parkName;
        this.tableParams.parkId = item.parkId;
      } else {
        this.tableParams.parkName = "";
        this.tableParams.parkId = "";
      }
    },
    handleCurrentChange(val) {
      this.tableParams.page = val;
      this.getDataTable();
    },
    rowTable(row) {
      this.tableParkName = row.parkName;
      this.$refs.singleTable.setCurrentRow(row);
      this.tagDesc = row.tagDesc;
      this.berthTurnover = row.berthTurnover;
      this.avgParkTime = row.avgParkTime;
      this.parkId = row.parkId;
      this.activePark = row;
      this.dayParkingData();
    },
    dayParkingNums(data) {
      let that = this;
      let parkingNums = this.$echarts.init(document.getElementById("parkingNums"));
      let weekend = [];
      let dayData = [];
      let week = [];
      let days = [];
      data.forEach((item, index) => {
        if (item.dayOfWeek > 5) {
          week.push(item);
        } else {
          days.push(item);
        }
      });
      week.forEach((item) => {
        weekend.push([item.berthTurnover, item.avgParkHour]);
      });
      days.forEach((item) => {
        dayData.push([item.berthTurnover, item.avgParkHour]);
      });
      parkingNums.setOption({
        /* title: {
          text: '日均泊位周转次数 — 平均停车时长分析',
        }, */
        tooltip: {
          // trigger: 'axis',
          showDelay: 0,
          formatter: function (params) {
            if (params.componentType === "markLine") {
              if (params.data.yAxis) {
                return `<div>
                          <p>全市平均停车时长: ${params.data.yAxis} 小时</p>
                      </div>`;
              } else if (params.data.xAxis) {
                return `<div>
                          <p>全市日均泊位周转次数: ${params.data.xAxis} 次</p>
                      </div>`;
              }
            } else if (params.componentType === "series") {
              if (params.seriesName === "周一至周五") {
                return `<div>
                          <p>${days[params["dataIndex"]].date}</p>
                          <p>日均泊位周转次数: ${days[params["dataIndex"]].berthTurnover}(次)</p>
                          <p>平均停车时长: ${formatDuring(
                            days[params["dataIndex"]].avgParkHour,
                            "seconds"
                          )}</p>
                      </div>`;
              } else if (params.seriesName === "周六周日") {
                return `<div>
                          <p>${week[params["dataIndex"]].date}</p>
                          <p>日均泊位周转次数: ${week[params["dataIndex"]].berthTurnover}(次)</p>
                          <p>平均停车时长: ${formatDuring(
                            week[params["dataIndex"]].avgParkHour >= 1
                              ? week[params["dataIndex"]].avgParkHour
                              : 0,
                            "seconds"
                          )}</p>
                      </div>`;
              }
            }
          },
        },
        grid: {
          id: "g1",
          top: "20%",
          left: "3%",
          right: "7%",
          bottom: "3%",
          containLabel: true,
        },
        legend: {
          data: ["周一至周五", "周六周日"],
          left: "right",
        },
        xAxis: [
          // 日均泊位周转次数
          {
            type: "value",
            name: "(次)",
            scale: true,
            max: that.baseMaxX,
            min: 0,
            axisLabel: {
              formatter: "{value}",
            },
            splitLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
          },
        ],
        yAxis: [
          // 平均停车时长
          {
            type: "value",
            name: "(小时)",
            scale: true,
            max: that.baseMaxY,
            min: 0,
            axisLabel: {
              formatter: "{value}",
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed",
              },
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
          },
        ],
        series: [
          {
            name: "周六周日",
            color: "#f4416d",
            type: "scatter",
            // data: [[167.6, 71.4], [104.9, 58.2]] item.berthTurnover, item.avgParkHour
            data: weekend,
            markLine: {
              lineStyle: {
                color: "#4e76b5",
                type: "solid",
              },
              data: [
                // {type: 'average', name: '平均值'},
                { xAxis: that.baseLineX },
                { yAxis: that.baseLineY },
              ],
            },
          },
          {
            name: "周一至周五",
            color: "#1890ff",
            type: "scatter",
            data: dayData,
            // data: [[170.2, 77.3], [127.6, 32.3]],
            markLine: {
              lineStyle: {
                color: "#4e76b5",
                type: "solid",
              },
              data: [
                // {type: 'average', name: '平均值'},
                { xAxis: that.baseLineX },
                { yAxis: that.baseLineY },
              ],
            },
          },
        ],
      });
      /* parkingNums.on('showTip', function (param) {
        console.log(param);
      }) */
      // finished
      /* parkingNums.on('finished', () => {
        // console.log('初始化');
        // let result = parkingNums.convertToPixel({gridId: 'g1'}, [that.baseLineX, that.baseLineY]); // 转为页面像素坐标
        // console.log('1122-->', result);
      }) */
      /* parkingNums.getZr().on('mousemove', (params) => {
        // console.log('mouseover--->', params.offsetX, params.offsetY);
        let pointInPixel = [params.offsetX, params.offsetY]
        // console.log(parkingNums.containPixel('grid', pointInPixel)) // 检测是否在echarts图内
        if (parkingNums.containPixel('grid', pointInPixel)) {
          let result = parkingNums.convertToPixel({gridId: 'g1'}, [that.baseLineX, that.baseLineY]); // 转为页面像素坐标
          // console.log('1122-->', result);
          if (params.offsetX < result[0] && params.offsetY > result[1]) {
            // 低频长停车场
            // this.parkHotName = '低频长停车场'
          } else if (params.offsetX > result[0] && params.offsetY > result[1]) {
            // 热门车场
            // this.parkHotName = '热门车场'
          } else if (params.offsetX > result[0] && params.offsetY < result[1]) {
            // 高频短停车场
            // this.parkHotName = '高频短停车场'
          } else if (params.offsetX < result[0] && params.offsetY < result[1]) {
            // 冷门车场
            // this.parkHotName = '冷门车场'
          }
        }
      }) */
    },
    dayParkingData() {
      this.$axios
        .get("/acb/2.0/parkBerthStatic/parkAnalysis", {
          data: { "Session-Id": sessionStorage.token, parkId: this.parkId },
        })
        .then((res) => {
          this.baseLineY = res.value.parkBerthRanking.avgParkHour;
          this.baseLineX = res.value.parkBerthRanking.berthTurnover;
          this.baseMaxY = res.value.parkBerthRanking.maxAvgParkHour;
          this.baseMaxX = res.value.parkBerthRanking.maxBerthTurnover;
          this.dayParkingNums(res.value.parkBerthAnalysis);
        });
    },
    getDataLine() {
      this.$axios
        .get("/acb/2.0/parkBerthStatic/baseline", { data: { "Session-Id": sessionStorage.token } })
        .then((res) => {
          // console.log('line-->', res);
          // this.baseLineY = res.value.avgParkHour
          // this.baseLineX = res.value.berthTurnover
          this.parkData = res.value;
        });
    },
    getDataTable() {
      let url = "/acb/2.0/parkBerthStatic/ranking";
      this.tableParams["Session-Id"] = sessionStorage.token;
      this.$axios
        .get(url, {
          data: this.tableParams,
        })
        .then((res) => {
          if (res.state === 0) {
            this.total = parseInt(res.value.total);
            this.tableData = res.value.list;
            if (this.tableData.length) {
              this.tableParkName = res.value.list[0].parkName;
              this.tagDesc = res.value.list[0].tagDesc;
              this.berthTurnover = res.value.list[0].berthTurnover;
              this.avgParkTime = res.value.list[0].avgParkTime;
              this.parkId = res.value.list[0].parkId;
              this.$refs.singleTable.setCurrentRow(this.tableData[0]);
              this.dayParkingData();
            }
          }
        });
    },
  },
  mounted() {
    this.getDataLine();
    this.getDataTable();
  },
};
</script>
<style scoped lang="stylus" rel="stylesheet/stylus">
.parkingBerthAnalysis{
  min-width 1200px
}
.parkingRight
  margin-top 20px
  padding 20px
  height 1106px
  position relative
  box-shadow: 0px 6px 16px 0px rgba(21,39,108,0.2);
  background #fff
  h4
    font-size 24px
  p
    font-size 14px
    margin-top 15px
    margin-bottom 15px
.parking-icon
  font-size: 25px
  cursor: pointer
.parkingTop
  padding 10px 20px
  height 120px
  line-height 30px
  background-color #fff
  color #fff
  &.parkingTop_yellow{
    background: url('../../assets/img/bg1.png') no-repeat center/100% 100%;
  }
  &.parkingTop_blue{
    background: url('../../assets/img/bg2.png') no-repeat center/100% 100%;
  }
  &.parkingTop_cyan{
    background: url('../../assets/img/bg3.png') no-repeat center/100% 100%;
  }
  p:first-child
    margin-top: 10px
    font-size: 16px;
  p:last-child
    font-size: 20px;
    margin-top: 10px
    font-weight 600;

.parkH4{
  >span{
    font-size: 16px;
    opacity 0.65;
    vertical-align: bottom;
  }
  .parking-icon{
    font-size 16px;
  }
}
.parking_search{
  >>> .el-autocomplete{
    width 100%
  }
}
.parkRanking{
  position relative
  >>> .parkRanking_span{
    position absolute
    right 15px
    top 13px
    color #657EE6
    cursor pointer
  }
}
.park_h4_spack{
  display inline-block
  width: 4px;
  height: 15px;
  background: linear-gradient(225deg,#4aa1fa, #2468f2);
  border-radius: 1px;
  vertical-align: bottom;
}
.park_h4_spack_icon{
  color #ccc
  vertical-align: text-bottom;
}
.park_title_name{
  font-size: 18px;
  margin-bottom: 20px;
  padding: 10px;
}
.parking_map{
  box-shadow: 0px 6px 16px 0px rgba(21,39,108,0.2);
  padding: 20px;
  padding-top: 5px;
  margin-top: 20px;
  background #fff
}
.parking_echarts{
  box-shadow: 0px 6px 16px 0px rgba(21,39,108,0.2);
  padding: 20px;
  background #fff
}
</style>
